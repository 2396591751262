li {
    list-style-type: circle;
}

ul {
    list-style-type: disc;
}

.tab {
    display: inline-block;
    margin-left: 10px;
}