.form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px; /* Spacing between each form group */
}

.form-group > label {
    width: 150px; /* Adjust as needed */
    margin-right: 10px;
}
