:root {
    --theme-color: #2286F7
}

.gray-theme {
    background-color: "#F3F6F9";
}

.container {
    color: black;
    background-color: "#F3F6F9";
}