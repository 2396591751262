.App {
  text-align: centers;
  position: absolute;
  top: 10;
  width: 100%;
  box-sizing: border-box;

}

.row-style1 {
  vertical-align: top;
  background-color: #ECECEC;
  padding: 15px;
}

.row-detail {
  background-color: aqua;
}

.highlighted {
  box-shadow: 0 10px 15px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #a4a4a4;
  transition: box-shadow ease;
}