html {
    margin: 8px;
}

.input {
    display: flex;
    justify-content: space-between;
}

.text {
    resize: none;
    width: 48%;
}

.submit {
    display: block;
    margin: 10px auto;
}

.diff-code-insert .space .diff-code-edit {
    background-color: #eaffee;
}

.diff-code-delete .space .diff-code-edit {
    background-color: #fdeff0;
}

mark {
    background-color: #fc8484;
}

pre {
    font-family: monospace;
    width: auto;
    display: inline-block;
    white-space: pre;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
}